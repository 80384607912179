import React from 'react';
import {Card} from 'react-bootstrap';

class About extends React.Component {
  render() {
    return (
    	<div>
    	<br/>
    	<h2>About Us</h2><br/>
    	<div>
	  	  <Card>
		    <Card.Body>
		      <Card.Text>
	    		TechLake Pty Ltd is registered to provide computer and internet services to the communies in Australia.<br/>
	    		Our aim is to equip the community service organisations with the state of the art technical solutions and systems available in the industry. 
		      </Card.Text>
		    </Card.Body>
		  </Card>
    	</div>
    	
    		<br/><br/>
    	</div>
    );
  }
}

export default About;