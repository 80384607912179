import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import {BrowserRouter as Router, Switch, Route} from "react-router-dom";
import {Navbar, Nav, Card} from 'react-bootstrap';
import About from './About';
import Contact from './Contact';
import Home from './Home';
import header from './img/header3.jpg';

function App() {
  return (
    <div>
    	<Navbar bg="secondary" variant="dark" expand="md">
    		<Navbar.Brand href="/">TechLake</Navbar.Brand>
	      	<Navbar.Toggle aria-controls="responsive-navbar-nav" />
		    <Navbar.Collapse id="responsive-navbar-nav">
		    <Nav className="mr-auto">
		      <Nav.Link href="/">Home</Nav.Link>
		      <Nav.Link href="/about">About Us</Nav.Link>
		      <Nav.Link href="/contact">Contact Us</Nav.Link>
		    </Nav>
		    </Navbar.Collapse>
	    </Navbar>
		<Card>
		    <Card.Img variant="top" src={header} />
		</Card>
	    <Router>
	    	<div className="App">
	    	<Switch>
	            <Route exact path="/">
	            	<Home />
	            </Route>
	            <Route path="/about">
	            	<About />
	            </Route>
	            <Route path="/contact">
	  	        	<Contact />
	            </Route>
	        </Switch>
	        </div>
       </Router>
       <hr/>
       <div className="Footer">
       	TechLake Pty Ltd ABN 96 647 026 140 &nbsp;&nbsp; Copyright &copy; {new Date().getFullYear()}
       </div>
    </div>
  );
}

export default App;
