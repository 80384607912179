import React from 'react';
import {CardDeck, Card} from 'react-bootstrap';
import membershipImg from './img/registration-3960205_640.jpg';
import hardwareImg from './img/google-on-your-smartphone-1796337_640.jpg';
import servicesImg from './img/businessman-2606509_640.jpg';

class Home extends React.Component {
  render() {
    return (
    	<div>
    	<br/>
    	<h1>TechLake Pty Ltd</h1>
    	
    	<div>
			<b>We believe everything starts from the community. We are committed to serve them.</b><br/>&nbsp;
		</div>
    	
    	<CardDeck>
    	  <Card>
    	    <Card.Img variant="top" src={membershipImg} />
    	    <Card.Body>
    	      <Card.Title>Membership Platform</Card.Title>
    	      <Card.Text>
    	        A cloud based solution to serve your members as an online centre to manage their communication with you.
    	      </Card.Text>
    	    </Card.Body>
    	  </Card>
    	  <Card>
    	    <Card.Img variant="top" src={hardwareImg} />
    	    <Card.Body>
    	      <Card.Title>Hardware</Card.Title>
    	      <Card.Text>
    	        All your hardware requirements including computer, tablet, mobile devices and other accessories can
    	        be fulfilled by us.
    	      </Card.Text>
    	    </Card.Body>
    	  </Card>
    	  <Card>
    	    <Card.Img variant="top" src={servicesImg} />
    	    <Card.Body>
    	      <Card.Title>Services</Card.Title>
    	      <Card.Text>
    	        Your needs can be discussed with our consultants, we sure provide solutions for them.
    	      </Card.Text>
    	    </Card.Body>
    	  </Card>
    	</CardDeck>
    	
    	</div>
    );
  }
}

export default Home;