import React from 'react';

class Contact extends React.Component {
  render() {
    return (
    	<div>
    	<br/>
    	<h2>Contact Us</h2><br/>
    		You can contact us through the following channels:<br/>
    		<br/>
    		Email address: <a href="mailto:support@techlake.com.au">support@techlake.com.au</a><br/>
    	</div>
    );
  }
}

export default Contact;